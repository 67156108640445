<script type="module" lang="ts">
export type AvatarSize = "big" | "large" | "default"
</script>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    size?: AvatarSize
    image?: string
    name?: string
  }>(),
  {
    size: "default",
    image: "",
    name: undefined,
  },
)

const sizeClass = computed(() => {
  switch (props.size) {
    case "big":
      return "size-16"
    case "large":
      return "size-12"
    default:
      return "size-8"
  }
})
</script>

<template>
  <div class="avatar shrink-0" :class="sizeClass">
    <img
      v-if="image"
      class="rounded-full"
      :class="sizeClass"
      :src="image"
      :alt="name"
    />
    <svg
      v-else
      class="rounded-full"
      :class="sizeClass"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fill-rule="evenodd">
        <rect
          fill="#F2F5F9"
          fill-rule="nonzero"
          width="18"
          height="18"
          rx="9"
        />
        <path
          d="M3.286 15.954v-.048a4.286 4.286 0 0 1 4.206-4.205H10.508a4.286 4.286 0 0 1 4.206 4.205v.048A8.963 8.963 0 0 1 9 18c-2.17 0-4.16-.768-5.714-2.046ZM5.972 7.07a3.143 3.143 0 1 1 6.286 0 3.143 3.143 0 0 1-6.286 0Z"
          fill="#B0BBCE"
        />
        <rect
          stroke-opacity=".04"
          stroke="#000"
          stroke-width="1.1"
          x=".55"
          y=".55"
          width="16.9"
          height="16.9"
          rx="8.45"
        />
      </g>
    </svg>
  </div>
</template>
